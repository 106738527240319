<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Mobility Kits</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>                           
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'work_from_anywhere' }">Work From Anywhere</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'hybrid' }">Hybrid Working</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'hybird_working_manage_your_schedule' }">Manage Your Schedule</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'second_setup' }">30-Second Setup</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Mobility Kits</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                     <router-link :to="{ name: 'second_setup' }" class="page-link border-0 text_black"  aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link >
                                 </li>
                                 <li class="page-item">
                                    <router-link :to="{ name: 'staying_focused' }"
                                       class="page-link border-0 text_black" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Staying Focused</span>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->

      <!-- Set Yourself up for success Starts -->
      <section class="set_yourself bg_grey pt_44 pb_50">
         <div class="container-fluid custome_container">
             <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="image_left_side">
                        <img src="../assets/images/mobility_kits/mobility_banner.png" alt="img">
                     </div>
                  </div>
             </div>
         </div>
         <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#ergo_on',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      <!-- Set Yourself up for success Ends -->
      <!-- Ergo On the go Starts -->
      <section id="ergo_on" class="ergo_on">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-xl-10 col-md-12">
                  <div class="mb_40">
                     <h6 class="p_22 text_primary font_bold">Ergo on the go</h6>
                     <p class="p_22">If you are currently, or planning to alternate between the office and home, try
                        creating a mobility kit that allows you to easily set yourself up wherever you work.</p>
                     <p class="p_22">Make sure to pack an external keyboard, mouse and laptop riser, as well as a small
                        towel for extra back support or added seat height. And of course, don’t forget your chargers and
                        earbuds.</p>
                  </div>
                  <div class="">
                     <h6 class="p_22 text_primary font_bold mb_64">Things to pack along</h6>
                     <div class="rectangle_box">
                        <div class="row">
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/keyboard.png" alt="Keyboard">
                                 <p class="text-black p_22">Keyboard</p>
                              </div>
                           </div>
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/mouse.png" alt="Keyboard">
                                 <p class="text-black p_22">Mouse </p>
                              </div>
                           </div>
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/output.png" alt="Keyboard">
                                 <p class="text-black p_22">Laptop Riser </p>
                              </div>
                           </div>
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/towel.png" alt="Keyboard">
                                 <p class="text-black p_22">Small Towel </p>
                              </div>
                           </div>
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/charger.png" alt="Keyboard">
                                 <p class="text-black p_22">Chargers </p>
                              </div>
                           </div>
                           <div class="col-6 col-md-4 col-lg-2">
                              <div class="thinks_to_pack">
                                 <img src="../assets/images/mobility_kits/earbuds.png" alt="Keyboard">
                                 <p class="text-black p_22">Headphones</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </section>
      <!-- Ergo On the go Ends -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Office Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'hybrid' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h1.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Hybrid Working</h6>
                                 <p>Working at home as well as the office? We’ve got you covered. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'hybird_working_manage_your_schedule' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h2.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Manage Schedule </h6>
                                 <p>Get your schedule under control in 3 easy steps.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'second_setup' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h3.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">30-Second Setup</h6>
                                 <p>No matter where you work, set yourself up for success. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mobility_kits' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h4.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mobility Kits</h6>
                                 <p>It’s easy to stay comfortable wherever you work.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'staying_focused' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h5.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Staying Focused</h6>
                                 <p>Staying focused requires skill, patience and practice. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'anywhere_stretches' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/hybird/h6.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Anywhere Stretches</h6>
                                 <p>Be kind to your body and take regular stretch breaks. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>

<script>
   export default {
      name: "mobility_kits",
   }
</script>